window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    
    var mailAdress;
    // console.log(event.detail.screenName)
    
    switch(event.detail.screenName) {
        case "benefit-screen":
            mailAdress = event.detail.fieldsSimple.mailAdressBenefit;
            break;
        case "consultation-screen":
            mailAdress = event.detail.fieldsSimple.mailAdressConsultation;
            break;
        case "magazin-screen":
              mailAdress = event.detail.fieldsSimple.mailAdressMagazin;
            break;
        default:
            console.log('Error Variable');
    }
    
    // console.log(mailAdress);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://new.api.smart-care-assistant.ucura.com/api/v1/account");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8")


    const body = JSON.stringify({
      email: mailAdress,
      consents: {"tc": true, "privacy": true}
    });
    
    xhr.onload = () => {
  
      if (xhr.readyState == 4 && xhr.status == 200) {
        // console.log(JSON.parse(xhr.responseText));
        
        const obj = JSON.parse(xhr.responseText);
    
        const date = new Date();
        date.setDate(date.getDate() + 30);
        const session = new Session(obj.accessToken, obj.refreshToken);
        const sessionString = JSON.stringify(session);
        setCookie('session', sessionString, date, '/', '.deinepflege.de', false, 'Strict');
                
                
      } else {
        console.log(`Error: ${xhr.status}`);
      }
    };
    xhr.send(body);
    
});


function setCookie(name, value, expires, path, domain, secure, sameSite) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
  if (expires) {
    cookieString += `expires=${expires.toUTCString()};`;
  }
  if (path) {
    cookieString += `path=${path};`;
  }
  if (domain) {
    cookieString += `domain=${domain};`;
  }
  if (secure) {
    cookieString += 'secure;';
  }
  if (sameSite) {
    cookieString += `SameSite=${sameSite};`;
  }
  document.cookie = cookieString;
}

// Session class equivalent in JavaScript
class Session {
  constructor(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }
}

